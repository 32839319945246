<template>
  <div>
    <v-container>
      <page-title title="Corrective Action Preventive Action (CAPA)">
        <template slot="action">
          <approval-template v-if="modulePermission('cpar', 'create')"></approval-template>
          <export-data v-if="modulePermission('cpar', 'export')" class="ms-1"></export-data>
        </template>
      </page-title>
      <v-row>
        <v-col>
          <v-card elevation="3">
            <v-tabs v-model="tab">
              <v-tab>My List</v-tab>
              <v-tab v-if="modulePermission('cpar', 'approve')">
                <v-badge dot v-if="badgeMyApproval">My Approval</v-badge>
                <span v-else>My Approval</span>
              </v-tab>
              <v-tab>
                <v-badge dot v-if="badgeMyTask">My Task</v-badge>
                <span v-else>My Task</span>
              </v-tab>
              <v-tab v-if="modulePermission('cpar', 'list-on-process')">
                <span>On Process</span>
              </v-tab>
              <v-tab v-if="modulePermission('cpar', 'list-closed')">
                <span>Closed</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <ListData></ListData>
              </v-tab-item>
              <v-tab-item v-if="modulePermission('cpar', 'approve')">
                <MyApproval ref="refMyApproval"></MyApproval>
              </v-tab-item>
              <v-tab-item>
                <MyTask></MyTask>
              </v-tab-item>
              <v-tab-item>
                <ListOnProcess></ListOnProcess>
              </v-tab-item>
              <v-tab-item>
                <ListClosed></ListClosed>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="9">
        </v-col>
        <v-col md="3">

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ListData from "./components/ListData.vue";
import ApprovalTemplate from "./components/ApprovalTemplate.vue";
import MyTask from "./components/MyTask.vue";
import MyApproval from "./components/MyApproval.vue";
import ExportData from "./components/ExportData.vue";
import ListOnProcess from "./components/ListOnProcess.vue";
import ListClosed from "./components/ListClosed.vue";
export default {
  components: {
    ApprovalTemplate,
    ListData,
    MyTask,
    MyApproval,
    ExportData,
    ListOnProcess,
    ListClosed,
  },
  data() {
    return {
      tab: null,

      dialog: null,
      approval_templates: [],

      badgeMyApproval: false,
      badgeMyTask: false,
    };
  },

  methods: {
    add() {
      this.dialog = true;
    },

    async showBadgeMyApproval(refresh) {
      const uri = "cpar/notif-exists";
      this.badgeMyApproval = false;
      this.badgeMyTask = false;

      if (refresh) this.AxiosStorageRemove("GET", uri);


      await this.$axios
        .get(uri, {
          sessionStorage: !refresh,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.badgeMyApproval = resData.data.approval;
            this.badgeMyTask = resData.data.task;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.axiosErrorHandler(error);

          this.badgeMyApproval = false;
          this.badgeMyTask = false;
        });
    },
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("cpar", "view", true);

    this.showBadgeMyApproval();
  },
};
</script>
